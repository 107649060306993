import { isBrowser } from "@/utils/env";

export const getSessionTypeFromUrl = () => {
  const urlParams = new URLSearchParams(isBrowser() ? window.location.search : {});
  return urlParams.get("session_type") || "";
};

/** Use for formatting anchor link for gatsby`s navigate() fns and <Link> component.
 *  Gatsby can`t redirect to anchor, and it causes troubles like your/url/#anchor
 * That fn was written for formatting such links to your/url#anchor
 * _ */
export const formatSeoSafeAnchorLink = (link: string, pathname: string) =>
  (link.startsWith("#") ? pathname + link : link);
